import "./src/styles/global.css"

const appendScript = (url) => {
  const script = document.createElement('script')
  script.src = url
  script.defer = script.async = true
  script.crossorigin = 'anonymous'
  document.head.appendChild(script)
}

const appendAdSense = () => {
  if (!document.querySelector('.adsbygoogle')) return
  if ((window.adsbygoogle || {}).loaded) return

  appendScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3703878768228760')
}

const appendGoogleTag = () => {
  // Google tag (gtag.js)
  appendScript('https://www.googletagmanager.com/gtag/js?id=AW-16919341285')

  window.dataLayer = window.dataLayer || []
  function gtag() { window.dataLayer.push(arguments) }
  gtag('js', new Date())
  gtag('config', 'AW-16919341285')
}

const appendTwitter = () => {
  // Twitter conversion tracking base code
  if (!window.twq) {
    const s = window.twq = function() {
      s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
    };
    s.version = '1.1';
    s.queue = [];
    const u = document.createElement('script');
    u.async = !0;
    u.src = 'https://static.ads-twitter.com/uwt.js';
    const a = document.getElementsByTagName('script')[0];
    a.parentNode.insertBefore(u, a)
  }
  window.twq('config','p7n2s');
  //  End Twitter conversion tracking base code
}

const appendExternalScripts = () => {
  appendAdSense()
}

export const onRouteUpdate = () => {
  window.addEventListener('scroll', appendExternalScripts, { once: true })
  appendGoogleTag()
  appendTwitter()
}
